
import Facebook from './social-icons/facebook.svg'
import Instagram from './social-icons/instagram.svg'
// import Twitter from './social-icons/twitter.svg'

import './SocialIcons.css';

function SocialIcons() {
  // const [socialLinks, loading] = useFetch(ApiUrls.SOCIALLINKS, 'true')

  return (
    <div className='social_wrapper'>
      <a href="https://www.facebook.com/shomikaiser71/" rel="noopener noreferrer" target="_blank">
          <img src={Facebook} alt="facebook" />
        </a>
        {/* <a href="twit.com" rel="noopener noreferrer" target="_blank">
          <img src={Twitter} alt="instagram" />
        </a> */}
        <a href="https://www.instagram.com/shomikaiser71/" rel="noopener noreferrer" target="_blank">
          <img src={Instagram} alt="instagram" />
        </a>
    </div>
  )
}

export default SocialIcons