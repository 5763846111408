import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper";

import Ss1 from '../../assets/images/story-slide-1.png'
import Ss2 from '../../assets/images/story-slide-2.png'
import Ss3 from '../../assets/images/story-slide-3.png'
import Ss4 from '../../assets/images/story-slide-4.png'

import "swiper/css";
import "swiper/css/effect-fade";
import './slide.css'
import './story-slider.css'

function StorySlider() {
  return (
    <section className="story_slider_section" id='story'>
      <div className="container">
        <div className="story_slider_wrapper">
          <Swiper
          autoHeight={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            effect={"fade"}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, EffectFade, Pagination]}
            className="story_slider"
          >
            <SwiperSlide className='story_slide'>
              <div className="col left">
                <img src={Ss1} alt="Banner" />
              </div>
              <div className="col right">
                <h6 className="sub_title">My Story</h6>
                <h1>Early Life</h1>
                <p>Shomi Kaiser was born on January 15, 1969, to Shahidullah Kaiser and Panna Kaiser. Her home district is Feni. Shomi’s father is martyred intellectual who wrote many famous novels. Shahidullah Kaiser was disappeared during the liberation war of Bangladesh,</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className='story_slide'>
              <div className="col left">
                <img src={Ss2} alt="Banner" />
              </div>
              <div className="col right">
                <h6 className="sub_title">My Story</h6>
                <h1>Media Career</h1>
                <p>In 1989, director Atiqul Haque Chowdhury was looking for a young girl who could speak the native Noakhailla dialect for his play Keba Apon Keba Por. And that's how Kaiser got her first role in acting.  Nakkhotrer Raat, Chhoto Chhoto Dheu, Sparsho, Ekjona, Ariyana, Akashey Anek Raat, Mukti, Antorey Nirantor, Swapno, Thikana are some remarkable works of her.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className='story_slide'>
              <div className="col left">
                <img src={Ss3} alt="Banner" />
              </div>
              <div className="col right">
                <h6 className="sub_title">My Story</h6>
                <h1>Entering the Business World</h1>
                <p>Kaiser is an Entrepreneur. She owns Dhansiri Production since 1997. It produced Mukti and Antorey Nirantor. In November 2013 her firm, Dhanshiri Communication Limited, got license for a private radio channel named Radio Active.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className='story_slide'>
              <div className="col left">
                <img src={Ss4} alt="Banner" />
              </div>
              <div className="col right">
                <h6 className="sub_title">My Story</h6>
                <h1>Policymaking and Social Activities</h1>
                <p>In May 2017, Kaiser was elected as one of the directors of Federation of Bangladesh Chambers of Commerce and Industry (FBCCI). In January 2018, she was elected as the president of e-Commerce Association (e-CAB).</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default StorySlider