import React from 'react'
import { Link } from 'react-router-dom'

import './navbar.css'

function Navbar() {
  return (
    <header>
      <div className="container">
        <Link to='/' className='brand_name'>Shomi Kaiser</Link>
        <a href="#story" className='link'>My Story</a>
      </div>
    </header>
  )
}

export default Navbar