import React from 'react'


import "swiper/css";
import BannerSlider from './slider/BannerSlider';
import StorySlider from './slider/StorySlider';

function Home() {
    return (
        <>
            <BannerSlider />
            <StorySlider />
        </>
    )
}

export default Home