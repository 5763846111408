import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper";

import Bns1 from '../../assets/images/banner-slide-1.png'
import Bns2 from '../../assets/images/banner-slide-2.png'
import Bns3 from '../../assets/images/banner-slide-3.png'

import "swiper/css";
import "swiper/css/effect-fade";
import './slide.css'
import './banner-slider.css'

function BannerSlider() {
  return (
    <section className="banner_slider_section">
      <div className="container">
        <div className="banner_slider_wrapper">
          <Swiper
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            effect={"fade"}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, EffectFade, Pagination]}
            className="banner_slider"
          >
            <SwiperSlide className='banner_slide'>
              <div className="col left">
                <div className="content">
                  <h1>Shomi Kaiser</h1>
                  <h2>media personality</h2>
                </div>
              </div>
              <div className="col right">
                <img src={Bns1} alt="Banner" />
              </div>

            </SwiperSlide>
            <SwiperSlide className='banner_slide'>
              <div className="col left">
                <div className="content">
                  <h1>Shomi Kaiser</h1>
                  <h2>Entrepreneur</h2>
                </div>

              </div>
              <div className="col right">
                <img src={Bns2} alt="Banner" />
              </div>
            </SwiperSlide>
            <SwiperSlide className='banner_slide'>
              <div className="col left">
                <div className="content">
                  <h1>Shomi Kaiser</h1>
                  <h2>social activist</h2>
                </div>


              </div>
              <div className="col right">
                <img src={Bns3} alt="Banner" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default BannerSlider