import React from 'react'
import SocialIcons from '../social/SocialIcons'
import './Footer.css'

function Footer() {
	return (
		<>
			<footer className="footer">
				<div className="container-fluid">
					<div className="row">
						<h3>&copy; All rights reserved by Shomi Kaiser</h3>
						<h3>Made with ❤ by &nbsp; 
							<a href="https://theantopolis.com/">Antopolis</a>&nbsp; & &nbsp; 
							<a href="https://goodybro.com/">GoodyBro</a>
						</h3>
					</div>
				</div>
				<SocialIcons />
			</footer>
		</>
	)
}

export default Footer